.list-table {
  width: 100%;
  border-collapse: collapse;
}

.list-table th,
.list-table td {
  width: 20%;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.list-table th {
  background-color: #f2f2f2;
}

.list-table tr:hover {
  background-color: #f5f5f5;
}
