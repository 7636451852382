.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin-right: 5px;
}

.pagination button {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  color: #333;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #ddd;
}
