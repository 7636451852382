/* Dashboard.css */

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
  }
  
  .search-container select,
  .search-container input {
    margin-right: 10px;
  }
  
  .dashboard-content {
    /* Add any styling for the content section */
  }
  